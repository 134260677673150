import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { MasterUserPropertiesService } from '@app/services/master-user-properties.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as featureActions from './actions';

@Injectable()
export class MasterUserPropertiesStoreEffects {
  constructor(
    private dataService: MasterUserPropertiesService,
    private actions$: Actions,
    private _errorHandler: ErrorHandlerService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService
          .load(action.payload.userId, action.payload.property_id)
          .pipe(
            map(
              ({ data }: any) =>
                new featureActions.LoadSuccessAction({
                  items: data,
                }),
            ),
            catchError((error) => {
              this._errorHandler.handle(error);
              return of(new featureActions.LoadFailureAction({ error }));
            }),
          ),
      ),
    ),
  );
}
