import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MasterUserPropertiesService {
  constructor(private http: HttpClient) {}

  load(user_id: number, property_id: number) {
    return this.http.get(
      `property/${property_id}/acl/user/${user_id}/mastered_properties`,
    );
  }
}
