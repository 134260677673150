import { UserPropertyAttached } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectUserPropertiesAttachedState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('userPropertiesAttached');

export const selectAllUserPropertiesAttachedItems: (
  state: object,
) => UserPropertyAttached[] = featureAdapter.getSelectors(
  selectUserPropertiesAttachedState,
).selectAll;

export const selectUserPropertiesAttachedById = (id: string) =>
  createSelector(
    selectAllUserPropertiesAttachedItems,
    (property: UserPropertyAttached[]) => {
      if (property) {
        return property.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectUserPropertiesAttachedError: MemoizedSelector<
  object,
  any
> = createSelector(
  selectUserPropertiesAttachedState,
  getError,
);

export const selectUserPropertiesAttachedIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectUserPropertiesAttachedState,
  getIsLoading,
);
