import { Item, MasterUserProperties } from '@app/models';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectMasterUserPropertiesState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('masterUserProperties');

export const selectAllMasterUserPropertiesItems: (
  state: object,
) => MasterUserProperties[] = featureAdapter.getSelectors(
  selectMasterUserPropertiesState,
).selectAll;

export const selectMasterUserPropertiesById = (id: string) =>
  createSelector(
    selectAllMasterUserPropertiesItems,
    (allMasterUserProperties: MasterUserProperties[]) => {
      if (allMasterUserProperties) {
        return allMasterUserProperties.find((p) => p.id === +id);
      } else {
        return null;
      }
    },
  );

export const selectMasterUserPropertiesError: MemoizedSelector<
  object,
  any
> = createSelector(
  selectMasterUserPropertiesState,
  getError,
);

export const selectMasterUserPropertiesIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectMasterUserPropertiesState,
  getIsLoading,
);
